import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalFilterService } from './global-filters.service';
import { GlobalFilterConfigService } from './global-filters-config.service';
import { GlobalFilterMapService } from './global-filters.map.service';

@Injectable()
export class GlobalFilterHelperService implements OnDestroy {
  orgs: any = []; // config.property
  categories: any = []; // config.property
  models: any = []; // config.property
  surveyResponses: any = [];
  orgsTree: any = [];
  subscriptions: Subscription[] = [];
  worker: Worker;
  constructor(
    public gbfService: GlobalFilterService,
    public gbfMapService: GlobalFilterMapService,
    public gbfConfig: GlobalFilterConfigService
  ) {
    this.subscriptions.push(
      gbfService.gbfChange.subscribe((event: { filter: any; val: any }) => {
        if (event.filter.name) {
          const dependendConfig = this.getDependentConfig(event.filter.name) || [];
          if (dependendConfig && dependendConfig.length) {
            gbfService.gbfApiCall.next(dependendConfig);
          }
        }
      })
    );
  }
  getDependentConfig(configName: string) {
    const dependendConfig: any = [];
    [...this.gbfConfig.filterConfig.config, ...this.gbfService.current_configs].forEach((fc: any) => {
      if (fc.dependon && fc.dependon.length && fc.dependon.indexOf(configName) !== -1) {
        dependendConfig.push(fc);
      }
    });
    return dependendConfig;
  }
  ngOnDestroy(): void {
    if (this.worker) {
      this.worker.terminate();
    }
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  getOrgs() {
    return this.orgs.length > 0 ? this.orgs : this['multi_orgs'];
  }
  list_to_tree(list: any) {
    const map = {};
    let node;
    const roots = [];
    let i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parent !== '0' && node.parent + '' !== '-1') {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.parent]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }
  treeToLinearLevelWise(list: any, property: string = '', parent: any = -1, empty: boolean = false, level: any = 0) {
    if (!property) {
      throw new Error('TreeToLinear property is required.');
    }
    if (empty) {
      this[property] = [];
    }
    level = level + 1;
    list.forEach((element: any) => {
      parent = element.parent || parent;
      const insertElement: any = {
        id: element.Name,
        level: level,
        label: element.Label,
        name: element.Label,
        value: element.Name,
        parent: parent,
        title: element.Title,
        mProps: element.mProps || {
          id: element.Name,
          level: level,
          name: element.Label,
          value: element.Name,
          parent: parent,
          title: element.Title,
        },
        IsChecked: element.IsChecked || false,
        IsPartialChecked: element.IsPartialChecked || false,
        active: element.active || false,
        hasChild: false,
      };
      if (element.children && element.children.length > 0) {
        insertElement['hasChild'] = true;
        this[property].push(insertElement);
        this.treeToLinear(element.children, property, element.Label, false, level);
      } else {
        this[property].push(insertElement);
      }
    });
  }
  treeToLinearVariable = (list: any, storage: any, property: any = '') => {
    if (!property) {
      throw new Error('TreeToLinear property is required.');
    }
    storage[property] = [];
    list.forEach((element: any) => {
      element.Data.forEach((dataNodes: any) => {
        dataNodes.Nodes.forEach((node: any) => {
          let insertElement = {
            id: node.Name,
            level: element.Level,
            label: node.Label,
            name: node.Label,
            value: node.NodeName,
            parent: dataNodes.Parent,
            title: element.Title,
            mProps: node.mProps || {
              ...node,
              parent: dataNodes.Parent,
              title: element.Title,
            },
            hasChild: node.HasChild || false,
          };
          storage[property].push(insertElement);
        });
      });
    });
  };
  treeToLinear(list: any, property: string = '', parent: any = -1, empty: boolean = false, level: any = 0) {
    if (!property) {
      throw new Error('TreeToLinear property is required.');
    }
    if (empty) {
      this[property] = [];
    }
    level = level + 1;
    list.forEach((element: any) => {
      parent = element.parent || parent;
      const insertElement: any = {
        id: element.id || element.Name,
        level: level,
        label: element.Label,
        name: element.Label,
        value: element.Name,
        parent: parent,
        title: element.Title,
        mProps: element.mProps || {
          id: element.id || element.Name,
          level: level,
          name: element.Label,
          value: element.Name,
          parent: parent,
          title: element.Title,
        },
        IsChecked: element.IsChecked || false,
        IsPartialChecked: element.IsPartialChecked || false,
        active: element.active || false,
        hasChild: element.hasChild || false,
      };
      if (element.children && element.children.length > 0) {
        insertElement['hasChild'] = true;
        this[property].push(insertElement);
        this.treeToLinear(element.children, property, element.id || element.Name, false, level);
      } else {
        this[property].push(insertElement);
      }
    });
  }
  treeToLinearN(list: any, property: string = '') {
    if (!property) {
      throw new Error('TreeToLinear property is required.');
    }
    this[property] = [];
    list.forEach((element: any) => {
      element.Data.forEach((dataNodes: any) => {
        dataNodes.Nodes.forEach((node: any) => {
          let insertElement: any = {
            id: node.Name,
            level: element.Level,
            label: node.Label,
            name: node.Label,
            value: node.NodeName,
            Title: element.Title,
            parent: dataNodes.Parent,
            orginalLevel: element.Level,
            mProps: node.mProps || {
              ...node,
              parent: dataNodes.Parent,
              title: element.Title,
            },
            hasChild: node.HasChild || false,
          };
          this[property].push(insertElement);
        });
      });
      // if (element.children && element.children.length > 0) {
      //     insertElement['hasChild'] = true;
      //     this[property].push(insertElement);
      //     this.treeToLinear(element.children, property, element.Label, false, level);
      // } else {
      //     this[property].push(insertElement);
      // }
    });

    if (property == 'orgs') {
      const levels = this[property].map((item: any) => item.level);
      const minLevel = Math.min(...levels);
      const maxLevel = Math.max(...levels);
      const allLevels = new Set();
      for (let i = minLevel; i <= maxLevel; i++) {
        allLevels.add(i);
      }
      const existingLevels = new Set(levels);
      const missingLevels: Array<any> = Array.from(allLevels).filter((level) => !existingLevels.has(level));
      if (missingLevels.length !== 0) {
        this.orgs.forEach((org: any) => {
          if (org.level > missingLevels[0]) {
            org.level = org.level - 1;
          }
        });
      }
      console.log('Missing levels:', missingLevels);
    }
  }
  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}
