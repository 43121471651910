import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core';

import { UserManager } from 'oidc-client-ts';

import { RealTimeNotificationService } from '@app/shared/services/realTimeNotification/real-time-notification.service';
import { SignalrService } from '@app/core/signalr.service';
import { environment, identityServerSSOConfig, identityServerTCIConfig } from '@env/environment';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';

import { OAuthService } from 'angular-oauth2-oidc';
import { WebStorageStateStore } from 'oidc-client-ts';
import { MyStartupService } from '@app/app.service';
import { LoaderService } from './../../core/loader.service';
import { Guid } from 'guid-typescript';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { AppConfig } from 'src/global.config';
@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SsoComponent implements OnInit {
  token: string;

  public loggedIn: boolean;
  public identity: any;

  error: string = undefined;

  permissions: NgxPermissionsObject;
  constructor(
    private routeParam: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    private realTimeNotificationService: RealTimeNotificationService,
    private signalRService: SignalrService,
    private ngxpermissions: NgxPermissionsService,
    private oauthService: OAuthService,
    private loaderService: LoaderService,
    private MystartupService: MyStartupService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.routeParam.queryParams.subscribe((params) => {
      this.token = params['ssoParam'];
      let clientName = params['c'];

      if (clientName != null && clientName != '') {
        clientName = clientName.replace(/^"(.*)"$/, '$1');
      }

      if (this.token) {
        localStorage.clear();
        localStorage.setItem('isSSOLogin', JSON.stringify(true));
        localStorage.setItem('ssoToken', JSON.stringify(this.token));
        this.getValuesFromToken();
      } else if (clientName && clientName != '') {
        localStorage.clear();
        //localStorage.setItem('isSSOLogin', JSON.stringify(true));
        localStorage.setItem('ssoClient', JSON.stringify(clientName));
        //// Get sso client info detail
        this.authService.getSSOClientInfo(clientName).subscribe((client: any) => {
          if (client != null) {
            client.ClientName = clientName;
            localStorage.setItem('ssoConfig', JSON.stringify(client));
            this.initSSO(client);
          }
        });
      } else {
        const client = JSON.parse(localStorage.getItem('ssoClient'));
        if (client != 'TCISSO') {
          const client = JSON.parse(localStorage.getItem('ssoClient'));
          if (client != null && client != '') {
            const ssoConfig = JSON.parse(localStorage.getItem('ssoConfig'));
            if (ssoConfig == null) {
              //this.router.navigate(['/unauthorized']);
              this.authService.getSSOClientInfo(client).subscribe((clientData: any) => {
                if (clientData != null) {
                  clientData.ClientName = client;
                  localStorage.setItem('ssoConfig', JSON.stringify(clientData));
                  this.initSSO(clientData);
                } else {
                  this.router.navigate(['/unauthorized']);
                }
              });
            } else {
              this.initSSO(ssoConfig);
            }
          } else {
            this.router.navigate(['/page-not-found']);
          }
        } else if (client === 'TCISSO') {
          this.token = JSON.parse(localStorage.getItem('ssoToken'));
          localStorage.setItem('isSSOLogin', JSON.stringify(true));

          this.getValuesFromToken();
        } else {
          localStorage.setItem('isSSOLogin', null);
          localStorage.setItem('ssoClient', null);

          this.router.navigate(['/unauthorized']);
        }
      }
    });
  }

  checkIsIdletimeoutSet() {
    let isIdleTimeout = JSON.parse(localStorage.getItem('isIdleTimeout'));
    let isUserReloggedIn = JSON.parse(localStorage.getItem('isUserReloggedIn'));
    if (isIdleTimeout && isIdleTimeout == true) {
      window.localStorage.clear();
      this.authService.signoutRedirect();
      return;
    } else if (isUserReloggedIn && isUserReloggedIn == true) {
      localStorage.removeItem('isUserReloggedIn');
      this.router.navigate(['/post-logout']);
    }
  }

  checkIsTokenExpired() {
    let isTokenExpired = JSON.parse(localStorage.getItem('isTokenExpired'));
    if (isTokenExpired && isTokenExpired == true) {
      window.localStorage.clear();
      this.authService.signoutRedirect();
      return;
    }
  }

  getValuesFromToken() {
    let code = decodeURI(this.token);
    code = code.replace(/\s+/g, '+');
    this.authService.getTokenParams({ Token: code }).subscribe((param) => {
      localStorage.setItem('cvp_session_id', '(S(' + param.SessionId + '))');
      localStorage.setItem('ssoConfig', JSON.stringify(param));

      this.authService.ssoLogin(param).subscribe((response) => {
        if (response) {
          this.initGenericSSO(param.Email);
        } else {
          this.router.navigate(['/unauthorized']);
        }
      });
    });
  }

  initGenericSSO(username: string) {
    const ssoConfig = JSON.parse(localStorage.getItem('ssoConfig'));
    this.oauthService.tokenEndpoint = ssoConfig.Authority + '/connect/token';
    this.oauthService.clientId = ssoConfig.IdentityClientId;
    this.oauthService.scope = ssoConfig.Scope;
    this.oauthService.dummyClientSecret = ssoConfig.Secret;

    this.oauthService.fetchTokenUsingPasswordFlow(username, 'password').then((resp: any) => {
      if (resp) {
        const res = {
          access_token: resp.access_token,
          expiry: 864000000000,
        };

        this.authService.saveSsoAuthToken(res);
        this.saveAuthData(res);
      }
    });
  }

  initSSO(client: any) {
    this.authService.initSSO(client);
    this.checkIsIdletimeoutSet();
    this.checkIsTokenExpired();
    const mgr = this.authService.getUserManager();
    this.authService.getCurrentUser().subscribe({
      next: (value) => {
        if (value) {
          this.authService.checkTokenExpiry(value.expires_at);
          this.authService.isLoggedIn = true;
          this.loggedIn = true;
          this.identity = value;
          this.initUser(client);
        } else {
          this.loggedIn = false;
          this.identity = '';
          this.authService.isLoggedIn = false;
          this.authService.signinRedirect();
        }
      },
      error: (e) => {
        // console.log(e);
        this.loaderService.showMain = false;
        this.router.navigate(['/oops']);
      },
    });
  }

  initUser(client: any) {
    const res = {
      access_token: this.identity.access_token,
      expiry: this.identity.expires_at,
    };

    //// create sso user if not exist
    const param = { ClientName: client.ClientName.toUpperCase(), ClientId: client.ClientId };
    this.authService.ssoUserProvision(param).subscribe((response: any) => {
      //// set default sso user language
      (window as any).GetDefaultLanguage(res.access_token, environment.apiUrl).then((r: any) => {
        (window as any).setLanguage(r.data);
        this.saveAuthData(res);
      });
    });
  }

  saveAuthData(res: any) {
    this.authService.storeAuthData(res).subscribe(
      (response) => {
        const token = this.authService.getSSOToken();
        if (token != null && token != undefined) response.token = this.authService.getSSOToken();

        const data = this.authService.onSignIn(response);

        if (environment.signalRConfigured) {
          this.signalRService.init();
          this.realTimeNotificationService.init();
          this.realTimeNotificationService.subscribeListeners();
        }
        this.authService.getLanguage().subscribe((langResponse) => {
          const isOrgList = response.DataAccessLevels.length > 1;
          this.authService.getOrgList(isOrgList).subscribe((res: any) => {
            if (res && res.length !== 0) {
              this.authService.orgListData = this.treeToLinearN(res);
            }
            this.MystartupService.data = langResponse;
            // localStorage.setItem('translations', JSON.stringify(langResponse));
            localStorage.setItem('translations', JSON['makeStringify'](langResponse));
            this.permissions = this.ngxpermissions.getPermissions();
            const Admin = this.permissions['Admin'] ? true : false;
            const Reporting = this.permissions['Reporting'] ? true : false;

            this.loaderService.showMain = false;
            if (localStorage.getItem('loginNotificationId') == null) {
              const id: string = Guid.create().toString();
              localStorage.setItem('appNotifyId', id);
              const notifyData = { message: 'Login Successfull', pageName: 'Login', module: 'Login', appNotifyId: id };
              this.notificationsService.ActivityNonRequestNotification(notifyData, true);
            }
            if (Reporting) {
              this.router.navigate(['/' + environment.reportingRoutePrefix]);
            } else if (Admin) {
              this.router.navigate(['/' + environment.reflecxRoutePrefix]);
            } else {
              this.router.navigate(['/unauthorized']);
            }
          });
        });
      },
      (reason) => {
        //  HideLoader();
        switch (reason.status) {
          case -1: {
            this.error = 'Some error occurred. Please check your internet connection or try again';
            this.loaderService.showMain = false;

            break;
          }
          case 400: {
            this.error = reason.Message;
            this.loaderService.showMain = false;

            break;
          }
          case 500: {
            this.error = reason.error.ExceptionMessage;
            this.loaderService.showMain = false;

            break;
          }
        }
      }
    );
  }

  private treeToLinearN(list: any): Array<any> {
    const orgData: Array<any> = [];
    list.forEach((element: any) => {
      element.Data.forEach((dataNodes: any) => {
        dataNodes.Nodes.forEach((node: any) => {
          let insertElement: any = {
            id: node.Name,
            level: element.Level,
            label: node.Label,
            name: node.Label,
            value: node.NodeName,
            Title: element.Title,
            parent: dataNodes.Parent,
            hasChild: node.HasChild || false,
            identifier: node.Identifier,
            nodeId: node.NodeId,
          };
          orgData.push(insertElement);
        });
      });
    });
    return orgData;
  }
}
